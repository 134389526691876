var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "listar-corretor pr-4" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0" },
        [
          _c("breadcrumb", {
            attrs: { title: "Corretores", actualPage: "Lista de Corretores" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "\n      flex\n      mt-8\n      mb-10\n      gap-3\n      w-full\n      md:w-1/3\n      lg:w-1/3\n      lp:w-1/3\n    "
        },
        [
          _vm.perfilHelpers.isInsurance()
            ? _c("Exportar", { attrs: { Type: 2, Label: "Corretores" } })
            : _vm._e(),
          _c(
            "router-link",
            { attrs: { to: "/corretores/cadastrar-novo-corretor" } },
            [
              _vm.permissoes && _vm.podeCadastrarNovoCorretor
                ? _c("vs-button", [
                    _vm._v("\n        Cadastrar novo corretor\n      ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c(
            "vs-table",
            {
              staticClass: "table-list",
              attrs: {
                sst: true,
                search: "",
                noDataText: "Nenhum corretor encontrado",
                data: _vm.listaCorretores || [],
                hoverFlat: true
              },
              on: {
                search: _vm.handleSearch,
                "change-page": _vm.handleChangePage
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    return _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        {
                          key: indextr,
                          attrs: {
                            data: tr,
                            state: data[indextr].IsActive ? null : "danger"
                          }
                        },
                        [
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].BrokerId }
                            },
                            [
                              _c(
                                "vs-dropdown",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    "vs-custom-content": "",
                                    "vs-trigger-click": ""
                                  }
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "dark",
                                        type: "flat",
                                        title: "Ações"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "onpoint-dots-three-vertical icon-dots-three-vertical",
                                        attrs: { role: "button" }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "vs-dropdown-menu",
                                    { staticStyle: { width: "200px" } },
                                    [
                                      _c(
                                        "vs-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.goToDetalheCorretor(
                                                data[indextr]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "text-base px-6 py-2"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Abrir cadastro")
                                              ])
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "vs-dropdown-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openModalBlock(
                                                data[indextr].BrokerId,
                                                data[indextr].IsActive
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "text-base px-6 py-2"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    data[indextr].IsActive
                                                      ? "Bloquear"
                                                      : "Desbloquear"
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].BrokerDocument }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(_vm._s(data[indextr].BrokerDocument))
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].BrokerName }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(_vm._s(data[indextr].BrokerName))
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].ComissionPercentage }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  _vm._s(data[indextr].ComissionPercentage) +
                                    "%"
                                )
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: {
                                data: data[indextr].ValidityRegistration
                              }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      new Date(
                                        data[indextr].ValidityRegistration
                                      ),
                                      "DD/MM/YYYY"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].SUSEPStatus }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  _vm._s(data[indextr].SUSEPStatus || "----")
                                )
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].IsActive }
                            },
                            [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v(
                                  _vm._s(data[indextr].IsActive ? "Não" : "Sim")
                                )
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "text-center",
                              attrs: { data: data[indextr].BlockDate }
                            },
                            [
                              data[indextr].BlockDate
                                ? _c("span", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            data[indextr].BlockDate,
                                            "DD/MM/YYYY"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    })
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th"),
                  _c("vs-th", { staticClass: "text-center" }, [_vm._v("CNPJ")]),
                  _c("vs-th", { staticClass: "text-center" }, [_vm._v("Nome")]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Comissão")
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Data de cadastro")
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Status SUSEP")
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Bloqueado")
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v("Data bloqueio")
                  ])
                ],
                1
              )
            ],
            2
          ),
          _c("vs-pagination", {
            staticClass: "mt-5",
            attrs: { total: _vm.numberOfPages },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: { title: _vm.modalTitle, active: _vm.openModalBlockBroker },
          on: {
            "update:active": function($event) {
              _vm.openModalBlockBroker = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _vm.brokerIsBlocked
              ? _c(
                  "div",
                  { staticClass: "vx-col pt-5 w-full mt-2" },
                  [
                    _c("label", { staticClass: "font-semibold" }, [
                      _vm._v("Justificativa")
                    ]),
                    _c("vs-textarea", {
                      model: {
                        value: _vm.justifyBlock,
                        callback: function($$v) {
                          _vm.justifyBlock = $$v
                        },
                        expression: "justifyBlock"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function($event) {
                        return _vm.closeModalBlock()
                      }
                    }
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function($event) {
                        return _vm.blockUnblockBroker()
                      }
                    }
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }